// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");

function handleEvent(onClickOutside, state, $$event) {
  if ($$event.type === "touchend") {
    state.isTouch.contents = true;
  }
  var match = $$event.type;
  var match$1 = state.isTouch.contents;
  var match$2 = state.ownRef.contents;
  if (match === "click" && match$1) {
    return ;
  }
  if (match$2 === undefined) {
    return ;
  }
  var targetElement = $$event.target;
  if (Caml_option.valFromOption(match$2).contains(targetElement)) {
    return ;
  } else {
    return Curry._1(onClickOutside, undefined);
  }
}

var fullSizeStyle = {
  height: "100%",
  width: "100%"
};

var emptyStyle = {};

function ClickOutside(props) {
  var onClickOutside = props.onClickOutside;
  var __fullSize = props.fullSize;
  var fullSize = __fullSize !== undefined ? __fullSize : true;
  var match = React.useState(function (param) {
        return {
                ownRef: {
                  contents: undefined
                },
                isTouch: {
                  contents: false
                }
              };
      });
  var state = match[0];
  React.useEffect(function (param) {
        document.addEventListener("touchend", (function (param) {
                return handleEvent(onClickOutside, state, param);
              }), true);
        document.addEventListener("click", (function (param) {
                return handleEvent(onClickOutside, state, param);
              }), true);
        return (function (param) {
                  document.removeEventListener("touchend", (function (param) {
                          return handleEvent(onClickOutside, state, param);
                        }), true);
                  document.removeEventListener("click", (function (param) {
                          return handleEvent(onClickOutside, state, param);
                        }), true);
                });
      });
  return JsxRuntime.jsx("div", {
              children: props.children,
              ref: Caml_option.some(function (r) {
                    state.ownRef.contents = (r == null) ? undefined : Caml_option.some(r);
                  }),
              style: fullSize ? fullSizeStyle : emptyStyle
            });
}

var $$Event;

var $$Element;

var $$Document;

var make = ClickOutside;

exports.$$Event = $$Event;
exports.$$Element = $$Element;
exports.$$Document = $$Document;
exports.handleEvent = handleEvent;
exports.fullSizeStyle = fullSizeStyle;
exports.emptyStyle = emptyStyle;
exports.make = make;
/* react Not a pure module */
