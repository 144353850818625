import React from 'react'
import Page from '../v5/homeDashboard/HomeDashboardPage.bs'
import { requireAuthenticatedAgent } from '../hoc/AuthenticatedComponent'
import { withMainLayoutNoPadding } from '../pagesComponents/MainApp'

const Page_ = requireAuthenticatedAgent(Page)

function HomePage() {
  return <Page_ />
}

HomePage.getLayout = withMainLayoutNoPadding

export default HomePage
